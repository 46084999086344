import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import AskUs from "./pages/AskUs";
import BeginAdmissions from "./pages/BeginAdmissions";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import JobDescription from "./pages/JobDescription";
import Review from "./pages/Review";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import TourNow from "./pages/TourNow";
import Services from "./pages/Services";
import Highlights from "./pages/Highlights";
import Oops from "./pages/Oops";
import ComingSoon from "./pages/ComingSoon";

import "./styling/App.css";
import "./styling/Pages.css";
import "./styling/Components.css";
import "./styling/fonts.css";

import staticData from "./data/staticData.json";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyA-atfT1ZXu0JJ2Tra0lh1sM_PkkQsPEj0",
  authDomain: "post-acute-athens.firebaseapp.com",
  projectId: "post-acute-athens",
  storageBucket: "post-acute-athens.appspot.com",
  messagingSenderId: "820295745712",
  appId: "1:820295745712:web:3d727076fceb34545f7b75",
  measurementId: "G-B1X4HXMSD3",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

const App = () => {
  return (
    <HelmetProvider>
      <div className="app">
        <Routes>
          <Route path="/" element={<ComingSoon to="/Home" />} />
          <Route path="/Home" element={<ComingSoon />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
};

export default App;
