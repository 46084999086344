const ComingSoon = () => {
  return (
    <main
      style={{
        height: "100vh",
        width: "100vw",
        background:
          "radial-gradient(50% 50% at 50% 50%, #154371 0%, #0D2D4D 100%)",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: "clamp(46px, 96px, 7vw)",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          COMING SOON...
        </p>
        <img
          alt="company logo"
          src={require(`../data/logos/coloredAthensLogo.png`)}
          style={{
            maxWidth: "clamp(200px, 310px, 80vw)",
            maxHeight: "clamp(325px, 435px, 80vw)",
          }}
        />
      </div>
    </main>
  );
};

export default ComingSoon;
