import SEO from "../components/SEO";

const careerData = [
  {
    department: "Apple",
    title: "Marketing Manager",
    datePosted: "2023-07-01",
  },
  {
    department: "Marketing",
    title: "Marketing Manager",
    datePosted: "2023-07-01",
  },
  {
    department: "Sales",
    title: "Sales Representative",
    datePosted: "2023-07-02",
  },
  { department: "HR", title: "HR Specialist", datePosted: "2023-07-03" },
  {
    department: "Zap",
    title: "Marketing Manager",
    datePosted: "2023-07-01",
  },
  {
    department: "Car Maker",
    title: "Marketing Manager",
    datePosted: "2023-07-01",
  },
];

const sortedArray = careerData.toSorted((a, b) => {
  const wordsA = a.department.split(" ");
  const wordsB = b.department.split(" ");
  return wordsA[0].localeCompare(wordsB[0]);
});

const Careers = ({ careers }) => {
  return (
    <main className="careers">
      <SEO
        title={careers.SEO.title}
        type={careers.SEO.type}
        img={require(`../data/images/${careers.SEO.img}`)}
        url={careers.SEO.url}
        description={careers.SEO.description}
        alt={careers.SEO.alt}
        name={careers.SEO.name}
      />
      <div className="hero-img" id="careers-hero-img">
        <p className="hero-title">{careers.heroTitle}</p>
      </div>
      <h1>{careers.header}</h1>
      <div className="box-border">
        <div className="row heading">
          {careers.tableHeading.map((item, i) => (
            <div className="cell" key={i}>
              {item}
            </div>
          ))}
        </div>
        {sortedArray.map((item, index) => (
          <div className="row" key={index}>
            <div className="cell">{item.department}</div>
            <div className="cell">{item.title}</div>
            <div className="cell">{item.datePosted}</div>
          </div>
        ))}
      </div>
      <div className="border-line-down hidden" />
    </main>
  );
};
export default Careers;
